/** @format */

import { Localized } from "@fluent/react";
import { connect } from "react-redux";
import { IconImage, IconTrash } from "components/icons";
import { useState, useRef, useMemo } from "react";
import { API_PATH, AUTH_COOKIE } from "constants.js";
import { getCookie } from "util/cookies";
import { parseImageLetter } from "../../chat/message";
import * as a from "actiontypes";
import Switch from "react-switch";

const mapStateToProps = state => {
	return {
		user: state.user,
		connection: state.connection,
		calendar_visibility: state.schedule.calendar.visibility,
		event_slug: state.event.slug,
		languages: state.event.languages,
		close_modals: state.close_modals,
		displayType: state.displayType
	};
};

const mapDispatchToProps = dispatch => ({
	setUserFetched: data => dispatch({ type: "SET_USER_DATA_FETCHED", payload: data }),
	signOut: () => dispatch({ type: "SIGNOUT" }),
	editField: (key, value) => dispatch({ type: "EDIT_FIELD", payload: { key, value } }),
	reconnectWebsocket: () => dispatch({ type: a.WS_RECONNECT })
});

const ProfileSettings = props => {
	const { user, setSuccess, setShowSuccess, setShowLoading, displayType, event_slug, languages = [] } = props;

	const {
		image = "",
		first_name = "",
		last_name = "",
		description = "",
		language = null,
		twitter = "",
		linkedin = "",
		website = "",
		company_name = "",
		title = "",
		guestuser,
		username = "",
		stealth = false,
		event_settings = {}
	} = user;
	const { engagement } = event_settings;
	const full_name = guestuser ? username : `${first_name} ${last_name}`;

	const [newImage, setNewImage] = useState(image);
	const [imageSizeError, setImageSizeError] = useState("");
	const [editedFields, setEditedFields] = useState([]);
	const [engagementChecked, setEngagementChecked] = useState(engagement);
	const imageRef = useRef();
	const imgUrl = useMemo(() => {
		if (!newImage && !image) return image;
		if (typeof newImage === "object") return URL.createObjectURL(newImage);
		return image;
	}, [newImage]);

	function editField(key, value) {
		if (!editedFields.includes(key)) {
			setEditedFields([...editedFields, key]);
		}
		props.editField(key, value);
	}

	async function handleImage(e) {
		if (!editedFields.includes("image")) {
			setEditedFields([...editedFields, "image"]);
		}
		if (!e) {
			setImageSizeError("");
			setNewImage("");
			return;
		}
		const imageSize = calcSizeInMB(e.target.files[0]?.size);
		if (imageSize > 4.99) {
			setImageSizeError(imageSize + "mb");
		} else {
			setImageSizeError("");
			setNewImage(e.target.files[0]);
		}
	}

	const handleEngagementVisibility = () => {
		if (engagementChecked) {
			setEngagementChecked(false);
			editBooleanField("engagement", "false", true);
		} else {
			setEngagementChecked(true);
			editBooleanField("engagement", "true", true);
		}
	};

	function editBooleanField(key, value, isEventSetting) {
		if (isEventSetting) {
			if (!editedFields.includes("event_settings")) {
				setEditedFields([...editedFields, "event_settings"]);
			}
			const bodyObj = {
				[key]: value === "true" ? true : false
			};
			props.editField("event_settings", bodyObj);
		} else {
			if (!editedFields.includes(key)) {
				setEditedFields([...editedFields, key]);
			}
			props.editField(key, value === "true" ? true : false);
		}
	}

	function isDisabled() {
		if (imageSizeError) {
			return true;
		}
		if (editedFields.length === 0) {
			return true;
		}
		if (
			(editedFields.includes("first_name") && user["first_name"].length === 0) ||
			(editedFields.includes("last_name") && user["last_name"].length === 0)
		) {
			return true;
		}
		return false;
	}

	async function submitForm() {
		setShowLoading(true);

		let formdata = new FormData();
		for (let key of editedFields) {
			if (key === "image") {
				formdata.append("image", newImage);
			} else if (typeof user[key] === "object") {
				formdata.append(key, JSON.stringify(user[key]));
			} else {
				formdata.append(key, user[key]);
			}
		}

		const headers = { Authorization: `Token ${getCookie(AUTH_COOKIE)}` };
		try {
			if (editedFields.length > 0) {
				const response = await fetch(`${API_PATH}users/me/?event=${event_slug}`, {
					headers: headers,
					method: "PATCH",
					body: formdata
				});
				if (response.ok) {
					let data = await response.json();
					props.setUserFetched(data);
					setSuccess(true);
				}
			}
		} catch {
		} finally {
			setEditedFields([]);
			setShowLoading(false);
			setShowSuccess(true);
			props.reconnectWebsocket();
		}
	}

	function calcSizeInMB(size) {
		return (size / (1024 * 1024)).toFixed(2);
	}
	return (
		<div className={`profile-settings-container ${displayType}`}>
			<div className="heading-row">
				<h5 className="profile-sub-heading">
					<Localized id="userprofile-profile-information-heading">Profile information</Localized>
				</h5>
			</div>
			<div className="profile">
				<div className="profile-image-content">
					<div className="profile-image-container">
						<div className="profile-image" style={newImage ? { backgroundImage: `url("${imgUrl}")` } : {}}>
							{parseImageLetter(newImage, full_name)}
						</div>
					</div>
					<button
						disabled={guestuser}
						onClick={() => imageRef.current.click()}
						className="image-upload-button"
					>
						<IconImage size="26" style={{ marginRight: "5px" }} />
						<Localized id="userprofile-change-image">Change image</Localized>
					</button>
					{image && (
						<button disabled={guestuser} onClick={() => handleImage()} className="image-upload-button">
							<IconTrash size="26" style={{ marginRight: "5px" }} />
							<Localized id="userprofile-remove-image">Remove image</Localized>
						</button>
					)}
					<input
						hidden
						type="file"
						ref={imageRef}
						accept={"image/jpg,image/png,image/jpeg"}
						onChange={e => handleImage(e)}
					/>
					<div>Max 5Mb</div>
					{imageSizeError && (
						<div style={{ color: "red", fontSize: "12px" }}>{`Image too big (${imageSizeError})`}</div>
					)}
				</div>

				<div className="profile-info">
					<div className="profile-subheading-row">
						<h5>
							<Localized id="userprofile-basic-information-subheading">Basic information</Localized>
						</h5>
					</div>

					<div className="profile-row">
						<p className="profile-info-label">
							<Localized id="userprofile-fullname-label">{"Full name"}</Localized>
						</p>
						<input
							value={first_name}
							className="profile-edit-input"
							disabled={guestuser}
							onChange={e => editField("first_name", e.target.value)}
						/>
						<input
							value={last_name}
							className="profile-edit-input"
							disabled={guestuser}
							onChange={e => editField("last_name", e.target.value)}
						/>
					</div>

					<div className="profile-row">
						<p className="profile-info-label">
							<Localized id="userprofile-company-label">{"Company"}</Localized>
						</p>
						<input
							value={company_name}
							className="profile-edit-input"
							disabled={guestuser}
							onChange={e => editField("company_name", e.target.value)}
						/>
					</div>

					<div className="profile-row">
						<p className="profile-info-label">
							<Localized id="userprofile-title-label">{"Title"}</Localized>
						</p>
						<input
							value={title}
							className="profile-edit-input"
							disabled={guestuser}
							onChange={e => editField("title", e.target.value)}
						/>
					</div>

					<div className="profile-row">
						<p className="profile-info-label">
							<Localized id="userprofile-description-label">{"Description"}</Localized>
						</p>
						<textarea
							value={description}
							rows={4}
							className="profile-edit-textarea"
							disabled={guestuser}
							onChange={e => editField("description", e.target.value)}
						></textarea>
					</div>

					<div className="profile-subheading-row">
						<h5>
							<Localized id="userprofile-localization-subheading">Localization</Localized>
						</h5>
					</div>
					<div className="profile-row">
						<p className="profile-info-label">
							<Localized id="userprofile-language-label">{"Language"}</Localized>
						</p>
						<select
							defaultValue={language}
							className="profile-edit-select"
							onChange={e => editField("language", e.target.value)}
						>
							{languages.map(lang => (
								<Localized key={lang} id={`userprofile-language-option-${lang}`}>
									<option value={lang}></option>
								</Localized>
							))}
						</select>
					</div>

					<div className="profile-subheading-row">
						<h5>
							<Localized id="userprofile-visibility-subheading">{"Visibility"}</Localized>
						</h5>
					</div>

					<div className="profile-row">
						<p className="profile-info-label">
							<Localized id="userprofile-visibility-label">
								{"Show me in the event`s attendee listing"}
							</Localized>
						</p>
						<select
							defaultValue={stealth}
							className="profile-edit-select"
							onChange={e => editBooleanField("stealth", e.target.value)}
						>
							<Localized id="userprofile-visibility-show-option">
								<option value="false">Show me in attendees</option>
							</Localized>
							<Localized id="userprofile-visibility-hide-option">
								<option value="true">Hide me from attendees</option>
							</Localized>
						</select>
					</div>

					<div className="profile-row">
						<p className="profile-info-label">
							<Localized id="engagement-nav-link">{"Engagement"}</Localized>
						</p>
						<Switch onChange={handleEngagementVisibility} checked={engagementChecked} />
					</div>

					<div className="profile-subheading-row">
						<h5>
							<Localized id="userprofile-contact-information-subheading">Contact information</Localized>
						</h5>
					</div>
					<div className="profile-row">
						<p className="profile-info-label">
							<Localized id="userprofile-contact-linkedin">{"Linkedin"}</Localized>
						</p>
						<input
							value={linkedin}
							className="profile-edit-input"
							disabled={guestuser}
							onChange={e => editField("linkedin", e.target.value)}
						/>
					</div>
					<div className="profile-row">
						<p className="profile-info-label">
							<Localized id="userprofile-contact-twitter">{"Twitter"}</Localized>
						</p>
						<input
							value={twitter}
							className="profile-edit-input"
							disabled={guestuser}
							onChange={e => editField("twitter", e.target.value)}
						/>
					</div>
					<div className="profile-row">
						<p className="profile-info-label">
							<Localized id="userprofile-contact-website">{"Website"}</Localized>
						</p>
						<input
							value={website}
							className="profile-edit-input"
							disabled={guestuser}
							onChange={e => editField("website", e.target.value)}
						/>
					</div>
					{!guestuser && (
						<div className="button-row">
							<button disabled={isDisabled()} onClick={() => submitForm()}>
								{<Localized id="userprofile-save-button">{"Save changes"}</Localized>}
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
